import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <div className="card">
    <Link to={post.frontmatter.path} className="link">
      <span className="color"></span>
      <span className="text">{post.frontmatter.title}</span>
    </Link>
  </div>
)
export default PostLink
