import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"

const IndexPage = ({ data: { site, allMarkdownRemark: { edges }, }, }) => {

	const Posts = edges
		.filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
		.map(edge => <PostLink key={edge.node.id} post={edge.node} />)

	return (
		<Layout>
			<Helmet>
				<title>{site.siteMetadata.title}</title>
				<meta name="description" content={site.siteMetadata.description} />
				<meta name="robots" content="index, follow" />
			</Helmet>
			<HeroHeader/>
			<div className="center">
				<img className="welcome-image" src={site.siteMetadata.home.image}  alt="Wild Thing Yoga" />
				<div className="hello-text">{site.siteMetadata.home.headline}</div>
				<div className="subline-text">bei <h1>{site.siteMetadata.home.subtitle}</h1></div>
				<div className="welcome-text" dangerouslySetInnerHTML={{__html: site.siteMetadata.home.text}}/>
				<div className="grids">
					{Posts}
				</div>
			</div>
		</Layout>
	)
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
        home {
        	image
        	headline
        	subtitle
					text
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
          	template
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`
